.custom-button {
    color: white !important; /* Override default styles */
    border: 1px solid white !important;
    box-shadow: 0 0 0 1px #004A8D inset !important;
    background-color: #004A8D !important;
    font-family: Quicksand, sans-serif !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
}


.public-auction-button {
    color: #004A8D !important; /* Override default styles */
    border: 1px solid #004A8D !important;
    box-shadow: 0 0 0 1px white inset !important;
    background-color: white !important;
    font-family: Quicksand, sans-serif !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
}