/* Target buttons in navigation and footer */
@media (max-width: 768px) {
    .nav-footer-button {
        font-size: 12px !important; /* Smaller font size */
        padding: 5px 10px !important; /* Adjust padding */
        white-space: nowrap !important; /* Prevent text wrapping */
    }

    .nav-footer-button.basic {
        border-width: 1px !important; /* Adjust border width if necessary */
    }
}

/* For very small screens (less than 480px) */
@media (max-width: 480px) {
    .nav-footer-button {
        font-size: 10px !important;
        padding: 0.8em !important;
        white-space: nowrap !important;
    }
}
