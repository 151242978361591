/*@media only screen and (min-width: 1200px) {*/
/*    .ui.grid.container {*/
/*        width: calc(1500px + 2rem) !important;*/
/*    }*/
/*}*/

/*@media only screen and (min-width: 992px) and (max-width: 1199px) {*/
/*    .ui.grid.container {*/
/*        width: calc(1050px + 2rem) !important;*/
/*    }*/
/*}*/

@media only screen and (max-width: 767px) {
    .custom-no-margin {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

/*#ReactGridGallery.ReactGridGallery {*/
/*    padding: 0 !important;*/
/*    display: block;*/
/*    width: 100%;*/
/*}*/